import { useTranslation } from "react-i18next";

export const GetMenuItemTranslation = (itemName: string) => {
  const { t } = useTranslation("mainMenuList");
  const translations: Record<string, { text: string; icon: string }> = {
    Dashboard: { text: t("Dashboard"), icon: "MainMenu/dashboard.png" },
    "All User": { text: t("All User"), icon: "MainMenu/user.svg" },
    "User Registration": {
      text: t("User Registration"),
      icon: "MainMenu/userRegistration.png",
    },
    "Bank Details": {
      text: t("Bank Details"),
      icon: "MainMenu/bankDetails.png",
    },
    "Account Report": {
      text: t("Account Report"),
      icon: "MainMenu/userLedger.png",
    },
    Transaction: {
      text: t("Transaction"),
      icon: "MainMenu/moneyGaveGot.png",
    },
    CashBook: { text: t("CashBook"), icon: "MainMenu/cashBook.png" },
    Setting: { text: t("Setting"), icon: "MainMenu/cashBook.png" },
    Report: { text: t("Report"), icon: "MainMenu/cashBook.png" },
  };
  const translation = translations[itemName] || { text: itemName, icon: "" };
  return translation;
};

export const GetSubMenuItemTranslation = (itemName: string) => {
  const { t } = useTranslation("subMenuList");
  const translations: Record<string, { text: string; icon: string }> = {
    Member: { text: t("Member"), icon: "MainMenu/member.svg" },
    "Add Bank": {
      text: t("Add Bank"),
      icon: "MainMenu/addBank.png",
    },
    "Bank Report": {
      text: t("Bank Report"),
      icon: "MainMenu/bankReport.png",
    },
    "Add User": { text: t("Add User"), icon: "MainMenu/addUser.png" },
    "User Transfer": {
      text: t("User Transfer"),
      icon: "MainMenu/user-transfer.png",
    },
    "User Report": {
      text: t("User Report"),
      icon: "MainMenu/userReport.png",
    },
    "Account Report": {
      text: t("Account Report"),
      icon: "MainMenu/ledgerReport.png",
    },
    // "Transaction": {
    //   text: t("Transaction"),
    //   icon: "MainMenu/addGaveGot.png",
    // },
    "Transaction Add": {
      text: t("Transaction Add"),
      icon: "MainMenu/addGaveGot.png",
    },
    "Transaction Reverse": {
      text: t("Transaction Reverse"),
      icon: "MainMenu/addGaveGot.png",
    },
    Report: {
      text: t("Report"),
      icon: "MainMenu/gaveGotReport.png",
    },
    "Add CashBook": {
      text: t("Add CashBook"),
      icon: "MainMenu/addCashBook.png",
    },
    "CashBook Report": {
      text: t("CashBook Report"),
      icon: "MainMenu/cashBookReport.png",
    },
    "Message Setting": {
      text: t("Message Setting"),
      icon: "MainMenu/cashBookReport.png",
    },
    "SMS Setting": {
      text: t("SMS Setting"),
      icon: "MainMenu/cashBookReport.png",
    },
    "Sent SMS Report": {
      text: t("Sent SMS Report"),
      icon: "MainMenu/cashBookReport.png",
    },
  };
  const translation = translations[itemName] || itemName;
  return translation;
};
